/**
 * DBS Slate Main Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will be loaded on pages that aren't the front page or blog.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Commonly used centering method w/absolute positioning
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Button mixin
 * @param $color1 - foreground color
 * @param $color2 - background color
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Type style definitions
 */
.hero-with-form__form button[type=submit], .who-contact__form button[type=submit] {
  color: #050C1A;
  font-family: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif;
  letter-spacing: 0.025em; }

.hero-with-form__form button[type=submit], .who-contact__form button[type=submit] {
  font-weight: 900; }

.basic-testimonial__author strong {
  border-left: 1px solid black;
  margin-left: .75rem;
  padding-left: .75rem; }

@media screen and (min-width: 48em) {
  .basic-testimonial .contain {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
  .basic-testimonial__icon {
    background: url("/icons/gradient-circle.svg") no-repeat center;
    background-size: cover;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 9rem;
    margin-right: 3rem;
    min-height: 9rem;
    min-width: 9rem;
    width: 9rem; }
    .basic-testimonial__icon svg {
      height: 3rem; } }

.basic-cta__wrapper {
  background: #7ACBC6;
  padding: 1.5rem;
  width: 100%; }

.basic-cta .button {
  display: block;
  margin: 0 auto -3.5rem;
  max-width: 20rem; }

@media screen and (min-width: 48em) {
  .basic-cta__wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 4rem;
    width: calc(100% - 4rem); }
  .basic-cta h3 {
    margin: 0; }
  .basic-cta .button {
    margin: 0 -8rem 0 auto; }
  .basic-cta__text {
    max-width: 42rem;
    padding-right: 2rem; } }

.brands {
  content-visibility: auto;
  padding: 3rem 0; }
  .brands .eyebrow {
    margin-bottom: 2.5rem; }
  .brands__icons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -1rem;
    width: calc(100% + 2rem); }
  .brands__icon {
    opacity: .8;
    padding: 1rem;
    text-align: center;
    width: 33.33%; }
    .brands__icon img {
      display: block;
      height: auto;
      max-height: 2rem;
      width: 100%; }
  @media screen and (max-width: 47.999em) {
    .brands__icon:nth-child(2) {
      display: none; } }
  @media screen and (min-width: 48em) {
    .brands__icons {
      margin: 0 -1.5rem;
      width: calc(100% + 1.5rem * 2); }
    .brands__icon {
      -ms-flex: 1;
      flex: 1;
      padding: 0 1.5rem;
      width: auto; }
      .brands__icon.springstone {
        -ms-flex-preferred-size: 12%;
        flex-basis: 12%; }
      .brands__icon.papa-johns img {
        max-height: 2.25rem; }
      .brands__icon img {
        max-height: 1.75rem; } }
  .brands.living-brand {
    margin-top: 3rem;
    padding: 0; }
    .brands.living-brand .brands__icon {
      width: 50%;
      opacity: 1; }
      .brands.living-brand .brands__icon img {
        max-height: 2.75rem; }
    @media screen and (max-width: 47.999em) {
      .brands.living-brand .brands__icon:nth-child(2) {
        display: block; }
      .brands.living-brand .brands__icon:nth-child(5) {
        display: none; } }
  .brands.bg-dark-blue {
    background: #1A2E4C; }

.client-screens {
  position: relative; }
  .client-screens .contain {
    width: 100%; }
  .client-screens__buttons {
    gap: 1.5rem; }
  .client-screens__screens {
    pointer-events: none; }
    .client-screens__screens--wrapper {
      height: 100%;
      overflow: hidden;
      position: absolute;
      left: 35%;
      width: 70%;
      z-index: 5; }
      .client-screens__screens--wrapper::after {
        background: url("/images/laptop-frame.png") no-repeat left;
        background-size: auto 100%;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 6; }
  .client-screens__screen {
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 3%;
    left: 6%;
    width: 100%; }
    .client-screens__screen img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .client-screens__screen img {
          height: 100%;
          object-fit: cover;
          object-position: top left; } }
    .client-screens__screen.active {
      opacity: 1; }
  .client-screens__content {
    margin-bottom: 3rem; }
  .client-screens__button {
    appearance: none;
    background: transparent;
    border: none;
    border-bottom: 4px solid transparent;
    height: 0;
    padding-bottom: 20%;
    position: relative;
    transition: .25s border-color, .25s background;
    width: calc(50% - 0.75rem); }
    .client-screens__button.active {
      background: rgba(0, 0, 0, 0.2);
      border-color: #F18977; }
    .client-screens__button img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 40%;
      width: 70%; }
      @supports (object-fit: contain) {
        .client-screens__button img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .client-screens__cta {
    margin-top: 3rem;
    position: relative; }
    .client-screens__cta .button {
      background-color: #153567 !important;
      border-color: #153567 !important;
      color: #ffffff !important;
      z-index: 2; }
      .client-screens__cta .button::before {
        background-image: url("/icons/arrow-teal.svg") !important;
        opacity: 1 !important; }
      .client-screens__cta .button::after {
        background: #153567 !important;
        content: '';
        height: calc(100% + 2px);
        opacity: 1 !important;
        position: absolute;
        top: -1px;
        left: 100%;
        transform: none !important;
        width: 100vw; }
  @media screen and (max-width: 47.999em) {
    .client-screens__screens {
      margin-top: 3rem; }
      .client-screens__screens--wrapper {
        height: 0;
        left: auto;
        right: -20vw;
        padding-bottom: 65%;
        position: relative;
        width: calc(100% + 20vw); }
    .client-screens__screen {
      height: 88%;
      top: 3%;
      left: 6%;
      width: 72%; } }
  @media screen and (min-width: 48em) {
    .client-screens {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .client-screens__left {
        -ms-flex-item-align: start;
        -ms-grid-row-align: start;
        align-self: start;
        position: relative;
        min-width: 35%;
        z-index: 1; }
      .client-screens__buttons {
        gap: 3rem 1.5rem; }
      .client-screens__screen {
        aspect-ratio: 1000 / 695;
        height: 89%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 9%;
        transform: translate(0, -50%);
        width: auto; }
      .client-screens__screens {
        aspect-ratio: 1000 / 695;
        margin-left: auto;
        max-width: calc(100% - 35%);
        min-width: 48rem;
        aspect-ratio: 1000 / 695;
        position: relative;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 65%;
        z-index: 2;
        height: auto; }
        .client-screens__screens--wrapper {
          left: 0;
          width: 100%; }
      .client-screens__content {
        margin-bottom: 4.5rem; } }
  @media screen and (min-width: 62em) {
    .client-screens__screens--wrapper {
      left: unset;
      right: -1.5rem; } }
  @media screen and (min-width: 80rem) {
    .client-screens {
      -ms-flex-pack: start;
      justify-content: flex-start; }
      .client-screens__left {
        width: 25.5rem; }
      .client-screens__screens {
        height: 608px;
        margin-left: unset;
        max-width: 800px;
        width: 800px;
        right: 0;
        position: absolute;
        transform: translate(0, -50%); } }

.contact-cta {
  padding: 1rem; }
  .contact-cta .contain {
    position: relative; }
    .contact-cta .contain:after {
      background: url("/icons/chevron_large.svg") no-repeat;
      background-position: -50vw 80%;
      background-size: auto 150%;
      content: '';
      height: 100%;
      opacity: .19;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0; }
      @media screen and (min-width: 48em) {
        .contact-cta .contain:after {
          background-size: auto 200%;
          background-position: -5vw 50%; } }
  .contact-cta__interior {
    margin: 0 auto;
    max-width: 55rem;
    padding: 1.5rem 0;
    position: relative;
    z-index: 2; }
    .contact-cta__interior .button.mobile-hidden,
    .contact-cta__interior .button.desktop-hidden,
    .contact-cta__interior .button.arrow {
      background-color: #7ACBC6;
      border-color: #7ACBC6;
      color: #050C1A; }
      .contact-cta__interior .button.mobile-hidden::before,
      .contact-cta__interior .button.desktop-hidden::before,
      .contact-cta__interior .button.arrow::before {
        background-image: url("/icons/arrow-teal.svg"); }
      .contact-cta__interior .button.mobile-hidden:hover, .contact-cta__interior .button.mobile-hidden:focus, .contact-cta__interior .button.mobile-hidden:active,
      .contact-cta__interior .button.desktop-hidden:hover,
      .contact-cta__interior .button.desktop-hidden:focus,
      .contact-cta__interior .button.desktop-hidden:active,
      .contact-cta__interior .button.arrow:hover,
      .contact-cta__interior .button.arrow:focus,
      .contact-cta__interior .button.arrow:active {
        background-color: #050C1A;
        border-color: #7ACBC6;
        color: #7ACBC6; }
  @media screen and (min-width: 48em) {
    .contact-cta {
      padding: 3rem; }
      .contact-cta__interior {
        padding: 3rem; } }
  @media screen and (min-width: 62em) {
    .contact-cta__interior {
      padding: 4.5rem; } }

.results .contain {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.results__main-item {
  opacity: 0;
  padding-top: 1.5rem;
  position: absolute;
  transition: opacity 0s, visibility 0s;
  visibility: hidden; }
  .results__main-item::before {
    border-top: 6px solid #3CA39D;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem; }
  .results__main-item.active {
    opacity: 1;
    position: relative;
    transition: opacity .25s, visibility 0s;
    visibility: visible; }

.results__list {
  list-style: none;
  margin-bottom: 2rem;
  padding: 0; }
  .results__list-item {
    cursor: pointer;
    line-height: 1.35;
    margin-bottom: 1.5rem;
    min-height: 3.65rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 5rem;
    position: relative; }
    .results__list-item:before {
      background: url("/icons/blue-check-white-bg.svg") no-repeat left center;
      background-size: auto 100%;
      content: '';
      height: 4rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 4rem;
      z-index: 2; }
    .results__list-item::after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 2rem;
      width: calc(100% - 5rem); }
    .results__list-item.active:after {
      background: linear-gradient(90deg, rgba(122, 203, 198, 0.4) 0%, rgba(217, 217, 217, 0) 85.32%); }

@media screen and (min-width: 48em) {
  .results .contain {
    -ms-flex-direction: row;
    flex-direction: row; }
  .results__main {
    padding-right: 3rem;
    width: 45%; }
    .results__main-item {
      font-size: 1.125rem; }
  .results__list {
    margin-bottom: 0;
    width: 55%; }
    .results__list-item {
      font-size: 1.125rem; } }

@media screen and (min-width: 62em) {
  .results .contain {
    -ms-flex-align: center;
    align-items: center; }
  .results__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 3rem;
    min-height: 25rem;
    width: 40%; }
  .results__list {
    columns: 2;
    column-gap: 3rem;
    width: 60%; }
    .results__list-item {
      break-inside: avoid-column; } }

/**
 * Note: A lot of styles are borrowed from the "Services" layout. These are just what is different.
 */
.icon-blocks.expertise .expertise__right {
  display: block;
  position: relative;
  z-index: 2; }

.icon-blocks.expertise .expertise__block {
  border-bottom: 1px solid #566879;
  padding: 1.5rem;
  position: relative; }
  .icon-blocks.expertise .expertise__block:last-child {
    border: none; }
  .icon-blocks.expertise .expertise__block-background {
    background: linear-gradient(135deg, #020A19 10%, #263854 100%);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .icon-blocks.expertise .expertise__block-background img {
      height: 100%;
      object-fit: cover;
      opacity: .125 !important;
      width: 100%; }
  .icon-blocks.expertise .expertise__block .eyebrow {
    font-size: 1.0556em; }
    @media (min-width: 48em) {
      .icon-blocks.expertise .expertise__block .eyebrow {
        font-size: 1.3195em; } }
  .icon-blocks.expertise .expertise__block .button--inline {
    display: block; }

@media screen and (min-width: 62em) {
  .icon-blocks.expertise .expertise__right {
    width: 50%; }
  .icon-blocks.expertise .expertise__block {
    padding: 2rem; } }

@media screen and (min-width: 48em) {
  .expertise__block .button--inline:after {
    top: -50%; }
  .portfolio-feature + .expertise {
    margin-top: 9rem; } }
  @media screen and (min-width: 48em) and (min-width: 62em) {
    .portfolio-feature + .expertise {
      margin-top: 10rem; }
      .portfolio-feature + .expertise .expertise__right:after {
        height: calc(100% + 15rem);
        top: -10rem; } }

/**
 * Inherits most styling from layout-why-dbs.scss
 */
.faqs.why-dbs .faqs__item {
  border-bottom: 1px solid #BCC2C8; }
  .faqs.why-dbs .faqs__item:first-child {
    border-top: 1px solid #BCC2C8; }
  .faqs.why-dbs .faqs__item-toggle {
    display: block;
    font-weight: 700;
    line-height: 1.4;
    padding: 1.5rem 3rem 1.5rem 0.75rem;
    position: relative;
    text-align: left;
    width: 100%; }
    .faqs.why-dbs .faqs__item-toggle:after {
      content: url("/icons/caret-down.svg");
      position: absolute;
      top: 50%;
      right: .75rem;
      transform: translateY(-50%); }
    .faqs.why-dbs .faqs__item-toggle.active:after {
      transform: translateY(-50%) rotate(180deg); }
  .faqs.why-dbs .faqs__item-answer {
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    padding: 0 .75rem;
    visibility: hidden;
    transition: .25s height; }
    .faqs.why-dbs .faqs__item-answer.active {
      margin-bottom: 1rem;
      visibility: visible; }
    .faqs.why-dbs .faqs__item-answer-text {
      padding-top: 1rem; }

.faqs.why-dbs .expand-all .collapse {
  display: none; }

.faqs.why-dbs .expand-all.active .collapse {
  display: inline; }

.faqs.why-dbs .expand-all.active .expand {
  display: none; }

.faqs.why-dbs.bg-dark .faqs__item-toggle {
  color: #ffffff; }
  .faqs.why-dbs.bg-dark .faqs__item-toggle::after {
    content: url("/icons/caret-down-white.svg"); }

/**
 * Flex Blocks Layout
 * DBS>Interactive
 */
.flex-blocks__heading {
  margin: 0 auto 3rem;
  max-width: 45rem; }

.flex-blocks__content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1.5rem; }

.flex-blocks .cell {
  padding-top: 1em;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .flex-blocks .cell {
      width: 50%; } }
  @media screen and (min-width: 62em) {
    .flex-blocks .cell {
      width: 33.33%; } }

.flex-blocks hr {
  background: linear-gradient(to right, #7ACBC6, #F18977);
  border: none;
  height: 1px;
  margin: 1.5rem auto 1.5rem 0;
  width: 6rem; }

@media screen and (max-width: 47.999em) {
  .flex-blocks .flex-blocks__content {
    margin: 0; }
  .flex-blocks .cell {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 1.5rem 0 !important; }
    .flex-blocks .cell:last-child {
      border: none;
      padding-bottom: 0 !important; } }

@media screen and (min-width: 48em) {
  .flex-blocks.two-up .cell:nth-last-child(-n+2) {
    padding-bottom: 0; } }

@media screen and (min-width: 62em) {
  .flex-blocks.two-up .cell {
    width: 50%; } }

@media screen and (min-width: 48em) {
  .flex-blocks.three-up .cell:nth-last-child(-n+2) {
    padding-bottom: 0; } }

@media screen and (min-width: 62em) {
  .flex-blocks.three-up .cell:nth-last-child(-n+3) {
    padding-bottom: 0; } }

@media screen and (min-width: 48em) {
  .flex-blocks.four-up .cell:nth-last-child(-n+2) {
    padding-bottom: 0; } }

@media screen and (min-width: 62em) {
  .flex-blocks.four-up .cell:nth-last-child(-n+3) {
    padding-bottom: 0; }
  .flex-blocks.four-up .cell {
    width: 25%; } }

@media screen and (min-width: 48em) {
  .flex-blocks.five-up .cell:nth-last-child(-n+2) {
    padding-bottom: 0; } }

@media screen and (min-width: 62em) {
  .flex-blocks.five-up .cell:nth-last-child(-n+3) {
    padding-bottom: 0; }
  .flex-blocks.five-up .cell {
    width: 20%; } }

@media screen and (min-width: 48em) {
  .flex-blocks.check-flex .cell {
    padding-left: 3rem;
    position: relative; }
    .flex-blocks.check-flex .cell:before {
      content: url("/icons/checkmark.svg");
      height: 1.4rem;
      position: absolute;
      left: 1rem;
      width: 1.4rem; } }

/**
 * Full Width Flex Layout
 * DBS>Interactive
 *
 * This layout takes up the full width of the browser and may have a background image.
 */
.full-width-slider__slide {
  margin: 0 1.5rem;
  width: 100%; }

.full-width-slider__element {
  margin: 0 auto;
  max-width: 62rem; }

.full-width-slider .flickity-page-dots {
  margin: 0 auto;
  max-width: 45rem; }
  .full-width-slider .flickity-page-dots .dot {
    border-radius: 0;
    -ms-flex: 1;
    flex: 1;
    height: 2px;
    margin: 0;
    opacity: 1 !important;
    width: auto; }
    .full-width-slider .flickity-page-dots .dot.is-selected {
      height: 6px; }

.full-width-slider .flickity-viewport {
  overflow: visible; }

@media screen and (min-width: 48em) {
  .full-width-slider__slide {
    margin: 0 3rem; } }

.hero-with-form__top {
  padding-bottom: 5rem;
  padding-top: 6rem; }
  .hero-with-form__top--content {
    margin-bottom: 3rem; }

.hero-with-form__bottom {
  margin-top: 5rem; }

.hero-with-form__form {
  background: #293747;
  margin-top: 7rem;
  padding: 1.5rem; }
  .hero-with-form__form button[type=submit] {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 0;
    color: #050C1A;
    display: inline-block;
    font-weight: 900;
    letter-spacing: 2px;
    padding: 1.5em 1.5em;
    text-align: center;
    text-decoration: none;
    transition: background-color .25s ease, border-color .25s ease, color .25s ease;
    text-transform: uppercase;
    -webkit-appearance: nowrap;
    white-space: nowrap;
    padding-bottom: 1rem;
    padding-top: 1rem; }
    .hero-with-form__form button[type=submit]:hover, .hero-with-form__form button[type=submit]:active, .hero-with-form__form button[type=submit]:focus {
      background-color: #050C1A;
      border-color: #ffffff;
      color: #ffffff; }
    @media screen and (min-width: 48em) {
      .hero-with-form__form button[type=submit] {
        padding: 1.5em 2.5em; } }
  .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text],
  .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email],
  .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel],
  .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea {
    background: #1e2834 !important;
    border: 1px solid #A9AEB4 !important;
    border-radius: 1px !important;
    color: white !important;
    font-weight: 600 !important; }
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text]:-ms-input-placeholder,
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email]:-ms-input-placeholder,
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel]:-ms-input-placeholder,
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea:-ms-input-placeholder {
      color: white;
      font-weight: 600; }
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text]::placeholder,
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email]::placeholder,
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel]::placeholder,
    .hero-with-form__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea::placeholder {
      color: white;
      font-weight: 600; }
  .hero-with-form__form form.freeform-form .freeform-row .freeform-label,
  .hero-with-form__form .freeform-row [class*="freeform-col-"].floating textarea:placeholder-shown:not(:focus) + * {
    color: white !important; }
  .hero-with-form__form form.freeform-fieldtype-checkbox {
    color: white;
    display: -ms-flexbox;
    display: flex; }
  .hero-with-form__form form.freeform-form .freeform-fieldtype-rich-text,
  .hero-with-form__form form.freeform-form .freeform-fieldtype-html {
    color: white !important; }
    .hero-with-form__form form.freeform-form .freeform-fieldtype-rich-text p,
    .hero-with-form__form form.freeform-form .freeform-fieldtype-rich-text span,
    .hero-with-form__form form.freeform-form .freeform-fieldtype-html p,
    .hero-with-form__form form.freeform-form .freeform-fieldtype-html span {
      color: white !important; }
  .hero-with-form__form .freeform-row [class*="freeform-col-"].floating input:placeholder-shown:not(:focus) + * {
    color: white !important; }
  .hero-with-form__form .freeform-row .floating label {
    background: none !important;
    color: #ffffff !important;
    font-weight: 600 !important; }
    .hero-with-form__form .freeform-row .floating label::after {
      color: #ffffff !important; }
  .hero-with-form__form--image {
    margin: -6rem auto 2rem;
    max-width: 23rem; }

@media screen and (min-width: 48em) {
  .hero-with-form {
    position: relative; }
    .hero-with-form__top--content {
      margin-bottom: 0;
      width: 50%; }
    .hero-with-form__bottom--content {
      width: 40%; }
    .hero-with-form__form {
      max-width: 33rem;
      position: absolute;
      top: 4rem;
      left: calc(50% + 5rem);
      width: 40%; } }

.icon-blocks {
  margin-top: 4rem;
  position: relative;
  /**
	 * Vertically stacked variant
	 */ }
  .icon-blocks__block {
    background: #19273e;
    border-bottom: 1px solid rgba(122, 203, 198, 0.4);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem; }
    .icon-blocks__block:last-child {
      border: none; }
    .icon-blocks__block .eyebrow.desktop-hidden {
      width: calc(100% - 5.5rem); }
    .icon-blocks__block .eyebrow a {
      font-weight: 900; }
  .icon-blocks__left {
    margin-bottom: 3rem; }
  .icon-blocks__icon {
    margin: 0 1rem .75rem 0;
    width: 4.5rem; }
  .icon-blocks__desc a {
    color: #ffffff !important; }
  .icon-blocks__link {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin: 1.5rem auto 0 0; }
  @media screen and (max-width: 47.999em) {
    .icon-blocks .button--box-arrow {
      margin-top: 1.5rem; } }
  @media screen and (min-width: 48em) {
    .icon-blocks__right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
    .icon-blocks__block {
      border-right: 1px solid rgba(122, 203, 198, 0.4);
      width: 50%; }
      .icon-blocks__block:nth-last-child(-n+2) {
        border-bottom: none; }
      .icon-blocks__block-heading {
        display: block; } }
  @media screen and (min-width: 62em) {
    .icon-blocks {
      margin-top: 0; }
      .icon-blocks__container {
        display: -ms-flexbox;
        display: flex; }
      .icon-blocks__left, .icon-blocks__right {
        width: 50%; }
      .icon-blocks__left {
        padding-right: 4rem; }
      .icon-blocks__right:after {
        height: calc(100% + 10rem);
        left: 6rem;
        top: -5rem; } }
  .icon-blocks.rows .icon-blocks__block {
    background: linear-gradient(135deg, #020A19 0%, #263854 100%);
    border-bottom: 1px solid #566879;
    position: relative; }
    .icon-blocks.rows .icon-blocks__block:last-child {
      border: none; }
  .icon-blocks.rows .icon-blocks__block-upper {
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
  .icon-blocks.rows .icon-blocks__link {
    bottom: 2rem;
    left: 7.5rem;
    right: unset;
    margin: 0;
    position: absolute; }
  @media screen and (max-width: 29.999em) {
    .icon-blocks.rows .icon-blocks__block-upper {
      padding-bottom: 1rem; }
    .icon-blocks.rows .icon-blocks__link {
      left: unset;
      right: 1rem; } }
  @media screen and (min-width: 48em) {
    .icon-blocks.rows .icon-blocks__block {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      width: 100%; }
    .icon-blocks.rows .icon-blocks__icon {
      margin: 0 1.5rem 0 0; }
    .icon-blocks.rows .icon-blocks__text {
      width: 100%; }
      .icon-blocks.rows .icon-blocks__text .icon-blocks__desc {
        display: none; }
      .icon-blocks.rows .icon-blocks__text .button--inline {
        display: block; } }
  @media screen and (max-width: 47.999em) {
    .icon-blocks.rows .icon-blocks__text {
      display: none; } }
  .icon-blocks.eight-blocks .icon-blocks__block {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .icon-blocks.eight-blocks h3 {
    margin: 0; }
  .icon-blocks.eight-blocks .icon-blocks__icon {
    height: 3rem;
    width: 3rem;
    margin: 0 1rem 0 0; }
  .icon-blocks.full-blocks .icon-blocks__container {
    position: relative; }
  .icon-blocks.full-blocks .icon-blocks__block .eyebrow.desktop-hidden {
    width: 100%; }
  .icon-blocks.full-blocks .icon-blocks__text {
    width: 100%; }
  @media screen and (min-width: 62em) {
    .icon-blocks.full-blocks .icon-blocks__block {
      -ms-flex-align: start;
      align-items: flex-start; }
    .icon-blocks.full-blocks .icon-blocks__left {
      padding: 0;
      width: 0; }
    .icon-blocks.full-blocks .icon-blocks__right {
      width: 100%; }
      .icon-blocks.full-blocks .icon-blocks__right:after {
        left: 60%; } }
  @media screen and (min-width: 48em) {
    .icon-blocks.two-up .icon-blocks__block {
      width: 50%; } }
  @media screen and (min-width: 48em) {
    .icon-blocks.three-up .icon-blocks__block {
      width: 33.33%; } }
  @media screen and (min-width: 62em) {
    .icon-blocks.four-up .icon-blocks__block {
      width: 25%; } }

.intro-three-col__cta {
  margin-bottom: 3rem; }

.intro-three-col__column {
  margin-bottom: 1.5rem; }

.intro-three-col__icon {
  height: 5rem;
  width: 5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  position: relative; }
  .intro-three-col__icon img,
  .intro-three-col__icon svg {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .intro-three-col__icon img,
      .intro-three-col__icon svg {
        height: 100%;
        object-fit: contain;
        object-position: left; } }

@media screen and (min-width: 48em) {
  .intro-three-col__column {
    margin-bottom: 0;
    width: calc(33.33% - 0.75rem); } }

@media screen and (min-width: 62em) {
  .intro-three-col__cta {
    border-right: 1px solid #F2F3F5;
    margin-bottom: 0;
    margin-right: 3rem;
    padding: 3rem 2rem 3rem 0; }
  .intro-three-col .flex-row-r-md .intro-three-col__cta {
    border-left: 1px solid #F2F3F5;
    border-right: none;
    margin-left: 3rem;
    padding-left: 3rem;
    padding-right: 0; } }

.interactive-content__links .interactive-content__link {
  appearance: none;
  background: transparent;
  border: none;
  border-left: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  color: white;
  display: block;
  font-weight: 600;
  padding: 1.25rem 1rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: .25s background, .25s border-left-color, .25s border-top-color, .25s border-bottom-color;
  width: 100%; }
  .interactive-content__links .interactive-content__link:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.25); }
  .interactive-content__links .interactive-content__link::after {
    background: url("/icons/arrow-right-white-long.svg") no-repeat right;
    background-size: 1.5rem auto;
    content: '';
    height: 100%;
    opacity: .25;
    position: absolute;
    top: 0;
    right: 1rem;
    transition: .25s opacity, .25s transform;
    width: 1.5rem; }
  .interactive-content__links .interactive-content__link:hover, .interactive-content__links .interactive-content__link:focus {
    background: #050C1A;
    border-left-color: #F18977;
    border-top-color: #050C1A;
    border-bottom-color: #050C1A; }
    .interactive-content__links .interactive-content__link:hover::after, .interactive-content__links .interactive-content__link:focus::after {
      opacity: 1;
      transform: translate3d(0.5rem, 0, 0); }

.interactive-content__panes {
  position: relative; }
  .interactive-content__panes--wrapper {
    border: .75rem solid black;
    border-radius: 34px;
    box-shadow: 8.801px 11.001px 22.333px 2.2px rgba(0, 0, 0, 0.5);
    height: 0;
    overflow: hidden;
    padding-bottom: 71%;
    position: relative;
    z-index: 3; }

.interactive-content .interactive-content__pane {
  background: #19273e;
  height: 0;
  overflow: hidden;
  padding-bottom: 75%;
  position: relative; }
  .interactive-content .interactive-content__pane img, .interactive-content .interactive-content__pane iframe {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .interactive-content .interactive-content__pane img, .interactive-content .interactive-content__pane iframe {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.interactive-content__colors {
  height: calc(100% + 3rem);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 30%;
  width: 200vw; }
  .interactive-content__colors::before {
    background: linear-gradient(to bottom, #19273e, transparent);
    content: '';
    height: 50%;
    position: absolute;
    width: 100%;
    z-index: 2; }
  .interactive-content__colors .interactive-content__color {
    height: 100%;
    width: 100%; }
  .interactive-content__colors--scroller {
    height: 100%;
    position: relative;
    width: 100%; }

@media screen and (min-width: 62em) {
  .interactive-content__links {
    padding-right: 4.5rem;
    width: 35%; }
  .interactive-content__panes {
    width: 65%; }
  .interactive-content__colors {
    height: calc(150% + 4.5rem); } }

.interactive-content + .layout {
  position: relative;
  z-index: 2; }

.image-blurb__text--wrap {
  border-left: 1px solid #7ACBC6;
  margin-top: 2rem;
  padding: 1rem 1.5rem; }

.image-blurb .arrow-link.boxed {
  padding-right: 0;
  padding-left: 4rem; }
  .image-blurb .arrow-link.boxed::after {
    background-size: 1.25rem !important;
    left: 0; }

@media screen and (min-width: 48em) {
  .image-blurb__image {
    width: 55%; }
  .image-blurb__blurb {
    width: 45%; }
    .image-blurb__blurb p:not(:last-child) {
      margin-bottom: 2rem; } }

/**
 * Page Header Flex Layout
 * DBS>Interactive
 */
.page-header {
  margin-top: 0 !important;
  /**
	 * CMS Classes
	 */ }
  .page-header h1:not(.eyebrow) {
    font-weight: 100; }
  .page-header__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .page-header__image, .page-header__content {
    padding: 3rem 0; }
  .page-header__content--wrapper {
    animation: opacity 0.75s ease-in-out both; }
  .page-header__image img {
    display: block;
    width: 100%; }
  .page-header .border-button {
    margin-top: 3rem; }
  .page-header .breadcrumbs {
    position: relative;
    top: -1.5rem; }
  @media screen and (max-width: 47.999em) {
    .page-header__image {
      max-width: 25rem; }
    .page-header__content {
      margin-bottom: 1.5rem;
      padding-bottom: 0; } }
  @media screen and (min-width: 48em) {
    .page-header .breadcrumbs {
      text-align-last: left;
      top: -3.5rem; }
    .page-header__container {
      -ms-flex-align: end;
      align-items: flex-end;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .page-header__content {
      padding: 5rem 0;
      width: 55%; }
    .page-header__image {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      max-width: 33rem;
      padding: 3rem;
      width: 45%; }
    .page-header.text-center .page-header__content {
      width: 100%; }
      .page-header.text-center .page-header__content--wrapper {
        animation: opacity 0.75s ease-in-out both, slideUp 0.75s ease-out both;
        margin: 0 auto;
        max-width: 46rem; }
    .page-header.text-center .page-header__image {
      display: none; } }
  .page-header.teal-accent .page-header__image {
    position: relative; }
    .page-header.teal-accent .page-header__image:after {
      background: #7ACBC6;
      content: '';
      position: absolute;
      top: 3rem;
      bottom: 3rem;
      left: 0;
      width: 100%;
      z-index: -1; }
  @media screen and (max-width: 47.999em) {
    .page-header.teal-accent .page-header__container {
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
    .page-header.teal-accent .page-header__content {
      padding-bottom: 2rem; }
    .page-header.teal-accent .page-header__image {
      margin: 0 -1.5rem;
      max-width: none;
      padding: 0; }
      .page-header.teal-accent .page-header__image:after {
        left: 6rem;
        bottom: -1rem; } }
  @media screen and (min-width: 48em) {
    .page-header.teal-accent .page-header__content {
      padding-right: 3rem; }
    .page-header.teal-accent .page-header__image {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      padding: 0 0 0 1.5rem; }
      .page-header.teal-accent .page-header__image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .page-header.teal-accent .page-header__image img {
            height: 100%;
            object-fit: cover;
            object-position: center; } } }
  @media screen and (min-width: 48em) {
    .page-header.halved .page-header__content,
    .page-header.halved .page-header__image {
      width: 50%; } }
  @media screen and (max-width: 47.999em) {
    .page-header.reverse-order .page-header__content {
      margin-bottom: 0;
      padding-bottom: 3rem;
      -ms-flex-order: 2;
      order: 2; }
    .page-header.reverse-order .page-header__image {
      margin-bottom: 1.5rem;
      padding-bottom: 0; } }
  .page-header.chevron-bg {
    background: #1B1D24; }
    @media screen and (min-width: 48em) {
      .page-header.chevron-bg::after {
        background-size: auto 300%;
        background-position: -20vw 50%; } }
  .page-header.hide-breadcrumbs .breadcrumbs {
    display: none; }

.portfolio:not(.portfolio-feature) .portfolio-filter {
  margin-bottom: 4.5rem; }
  .portfolio:not(.portfolio-feature) .portfolio-filter select {
    appearance: none;
    color: #3E767D;
    width: auto; }
  .portfolio:not(.portfolio-feature) .portfolio-filter__button {
    font-size: 2.42509em;
    background: none;
    border: none;
    color: #3E767D;
    display: inline-block;
    margin-top: .75rem;
    padding: 0 2em 0 0;
    position: relative; }
    @media (min-width: 48em) {
      .portfolio:not(.portfolio-feature) .portfolio-filter__button {
        font-size: 3.03136em; } }
    .portfolio:not(.portfolio-feature) .portfolio-filter__button:before {
      height: 4rem;
      width: 4rem;
      background: url("/icons/green-circle-down.svg") no-repeat right;
      background-size: auto 100%;
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .portfolio:not(.portfolio-feature) .portfolio-filter__button:after {
      content: '';
      display: block;
      border-bottom: 1px solid #3E767D;
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform .25s ease-in-out; }
    .portfolio:not(.portfolio-feature) .portfolio-filter__button:hover:after, .portfolio:not(.portfolio-feature) .portfolio-filter__button:focus:after {
      transform: scaleX(1);
      transform-origin: 0 100%; }
  .portfolio:not(.portfolio-feature) .portfolio-filter__screen {
    height: 100%;
    width: 100%;
    background: white;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    max-height: 100vh;
    overflow: auto;
    padding-left: 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(0, -100%, 0);
    transition: transform .5s ease, visibility 0s .5s linear;
    visibility: hidden;
    z-index: 5000; }
    .portfolio:not(.portfolio-feature) .portfolio-filter__screen a {
      color: #3E767D;
      display: inline-block;
      font-size: 1.65rem;
      font-weight: 400;
      margin-bottom: 3rem;
      opacity: 0;
      text-decoration: none;
      transform: translate3d(-3rem, 0, 0);
      transition: .65s transform ease-in-out, .65s opacity ease-in-out; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:after {
        content: '';
        display: block;
        border-bottom: 1px solid #3E767D;
        transform: scaleX(0);
        transform-origin: 100% 0;
        transition: transform .25s ease-in-out; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:hover:after, .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:focus:after {
        transform: scaleX(1);
        transform-origin: 0 100%; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:last-child {
        margin-bottom: 0; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:nth-child(2) {
        transition-delay: .2s; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:nth-child(3) {
        transition-delay: .35s; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:nth-child(4) {
        transition-delay: .5s; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:nth-child(5) {
        transition-delay: .65s; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a:nth-child(6) {
        transition-delay: .8s; }
    .portfolio:not(.portfolio-feature) .portfolio-filter__screen.active {
      transform: translate3d(0, 0, 0);
      transition: transform .5s ease, visibility 0s 0s linear;
      visibility: visible; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen.active a {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
  .portfolio:not(.portfolio-feature) .portfolio-filter__close {
    height: 4rem;
    width: 4rem;
    background: url("/icons/close-symbol-white.svg") no-repeat center;
    background-color: #3E767D;
    background-size: 50%;
    border: none;
    border-radius: 50%;
    margin-bottom: 3rem;
    min-height: 4rem;
    padding: 0; }

.portfolio:not(.portfolio-feature) .portfolio-grid__item {
  border-bottom: 1.5rem solid transparent;
  padding-bottom: 130%; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item-background {
    transition: .5s transform ease-in-out; }
    .portfolio:not(.portfolio-feature) .portfolio-grid__item-background img {
      object-position: top; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item-text {
    bottom: 0; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item-excerpt {
    max-height: initial; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item a {
    font-weight: 700;
    text-decoration: none; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item:hover .portfolio-grid__item-text {
    background: 0; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item:hover .portfolio-grid__item-excerpt {
    max-height: initial;
    padding-bottom: 0; }
  .portfolio:not(.portfolio-feature) .portfolio-grid__item:hover .portfolio-grid__item-background {
    transform: scale(1.05); }

@media (max-height: 44rem) {
  .portfolio:not(.portfolio-feature) .portfolio-filter__screen {
    padding: 1.5rem 0;
    -ms-flex-pack: start;
    justify-content: start; }
    .portfolio:not(.portfolio-feature) .portfolio-filter__screen a {
      margin-bottom: 1rem; } }

@media screen and (min-width: 48em) {
  .portfolio:not(.portfolio-feature) .portfolio-filter {
    padding-left: 1.5rem; }
    .portfolio:not(.portfolio-feature) .portfolio-filter__screen {
      padding-left: 20vw; }
      .portfolio:not(.portfolio-feature) .portfolio-filter__screen a {
        font-size: 2.42509em; } }
      @media screen and (min-width: 48em) and (min-width: 48em) {
        .portfolio:not(.portfolio-feature) .portfolio-filter__screen a {
          font-size: 3.03136em; } }

@media screen and (min-width: 48em) {
  .portfolio:not(.portfolio-feature) .portfolio-grid__item {
    padding-bottom: 75%; }
    .portfolio:not(.portfolio-feature) .portfolio-grid__item:nth-child(odd) {
      border-right: .75rem solid transparent; }
    .portfolio:not(.portfolio-feature) .portfolio-grid__item:nth-child(even) {
      border-left: .75rem solid transparent;
      transform: translateY(-25%); }
    .portfolio:not(.portfolio-feature) .portfolio-grid__item-text {
      padding: 3rem; } }

@media screen and (min-width: 62em) {
  .portfolio:not(.portfolio-feature) {
    margin-top: 9rem; }
    .portfolio:not(.portfolio-feature) .portfolio-filter {
      margin-bottom: 6rem; }
    .portfolio:not(.portfolio-feature) .portfolio-grid__item {
      width: 50%; } }

.portfolio.layout .portfolio-grid__item-excerpt {
  font-size: 1.39286em; }
  @media (min-width: 48em) {
    .portfolio.layout .portfolio-grid__item-excerpt {
      font-size: 1.74108em; } }

@media screen and (min-width: 48em) {
  .portfolio.layout .portfolio-grid__item-excerpt {
    font-size: 1.83788em; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .portfolio.layout .portfolio-grid__item-excerpt {
      font-size: 2.29736em; } }

/**
 * Re-usable items related to portfolio layouts/pages
 */
.portfolio-grid__item {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .portfolio-grid__item-background, .portfolio-grid__item-background:after {
    height: 101%;
    width: 101%;
    position: absolute;
    top: -1px;
    left: 0; }
  .portfolio-grid__item-background:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
    content: ''; }
  .portfolio-grid__item-background img {
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .portfolio-grid__item-text {
    padding: 1.5rem;
    padding-bottom: 2rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    transition: background .35s ease-in-out;
    width: 100%;
    will-change: background; }
    .portfolio-grid__item-text .button--inline {
      display: block;
      margin-bottom: 1rem; }
  .portfolio-grid__item-excerpt {
    font-size: 1.0556em;
    line-height: 1.3;
    max-height: 0;
    overflow: hidden;
    transition: max-height .35s ease-in-out;
    will-change: max-height; }
    @media (min-width: 48em) {
      .portfolio-grid__item-excerpt {
        font-size: 1.3195em; } }
  .portfolio-grid__item:hover .portfolio-grid__item-text, .portfolio-grid__item:focus .portfolio-grid__item-text {
    background-color: rgba(5, 12, 26, 0.77); }
  .portfolio-grid__item:hover .portfolio-grid__item-excerpt, .portfolio-grid__item:focus .portfolio-grid__item-excerpt {
    max-height: 10rem; }

@media screen and (min-width: 48em) {
  .portfolio-grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .portfolio-grid__item {
      padding-bottom: 50%;
      width: 50%; } }

@media screen and (min-width: 62em) {
  .portfolio-grid__item {
    padding-bottom: 33.33%;
    width: 33.33%; } }

.portfolio-blocks {
  position: relative;
  z-index: 3; }
  .portfolio-blocks h3 {
    margin-bottom: 0;
    max-width: 47rem;
    padding-right: 3rem; }
  .portfolio-blocks__heading {
    margin-bottom: 3rem; }
  .portfolio-blocks .button--box-arrow {
    min-width: 18rem; }
  .portfolio-blocks .portfolio-grid__item-excerpt {
    color: #fff; }
  .portfolio-blocks .portfolio-blocks__tagline {
    display: none; }
  .portfolio-blocks .button--inline + .button--inline {
    display: none; }
  .portfolio-blocks.port-blocks-tall {
    padding-bottom: 0 !important;
    padding-top: 0 !important; }
    .portfolio-blocks.port-blocks-tall .portfolio-blocks__heading,
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item-excerpt {
      display: none; }
    .portfolio-blocks.port-blocks-tall .portfolio-blocks__tagline {
      display: block; }
    .portfolio-blocks.port-blocks-tall .portfolio-blocks__tagline + .button--inline {
      font-size: 1.125rem; }
    .portfolio-blocks.port-blocks-tall .button--inline.bold {
      letter-spacing: normal;
      text-transform: none; }
      .portfolio-blocks.port-blocks-tall .button--inline.bold::after {
        content: none; }
      .portfolio-blocks.port-blocks-tall .button--inline.bold + .button--inline {
        display: inline-block; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item {
      border-bottom: 4px solid transparent; }
      .portfolio-blocks.port-blocks-tall .portfolio-grid__item:hover .portfolio-grid__item-text {
        background: transparent; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item-background img {
      object-position: top; }
  @media screen and (min-width: 48em) {
    .portfolio-blocks__heading {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .portfolio-blocks .portfolio-grid {
      margin-bottom: -10rem; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid {
      margin-bottom: 0;
      margin-left: -4px;
      margin-right: -4px; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item {
      border-bottom: none;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      padding-bottom: 58%;
      width: 33%; }
      .portfolio-blocks.port-blocks-tall .portfolio-grid__item-text {
        bottom: 2rem; } }
  .portfolio-blocks.bg-light {
    background: #ffffff;
    padding: 0; }
    .portfolio-blocks.bg-light .eyebrow {
      color: #1869B9 !important; }
    .portfolio-blocks.bg-light .portfolio-grid {
      margin: 0 auto !important; }

.portfolio-feature {
  padding-top: 2rem;
  position: relative; }
  .portfolio-feature::before {
    background: #1B1D24;
    content: '';
    height: 40%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .portfolio-feature__block {
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    position: relative; }
    .portfolio-feature__block--image {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
      .portfolio-feature__block--image img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .portfolio-feature__block--image img {
            height: 100%;
            object-fit: cover;
            object-position: bottom; } }
      .portfolio-feature__block--image::before {
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.95));
        content: '';
        height: 60%;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1; }
    .portfolio-feature__block--content {
      position: relative;
      z-index: 3; }
    .portfolio-feature__block--spacer {
      padding-top: 75%; }
  @media screen and (min-width: 48em) {
    .portfolio-feature {
      padding-top: 3rem; }
      .portfolio-feature .contain {
        gap: 1rem; }
      .portfolio-feature__block {
        margin-bottom: 0;
        width: 33.33%; }
        .portfolio-feature__block--image img {
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%; }
          @supports (object-fit: contain) {
            .portfolio-feature__block--image img {
              height: 100%;
              object-fit: cover;
              object-position: center; } }
        .portfolio-feature__block--spacer {
          padding-top: 150%; } }

.portfolio-slider-cards {
  background: #050C1A; }
  .portfolio-slider-cards__slides {
    position: relative; }
  .portfolio-slider-cards__slide {
    margin-right: 1.5rem;
    position: relative;
    width: 100%; }
  .portfolio-slider-cards__card {
    background: #ffffff;
    margin: 6rem 1.5rem;
    max-width: 24rem;
    padding: 1.5rem;
    position: relative;
    z-index: 2; }
    .portfolio-slider-cards__card .arrow-link {
      margin-top: 3rem; }
  .portfolio-slider-cards__icon {
    margin-bottom: 1rem;
    width: 8rem; }
    .portfolio-slider-cards__icon img {
      width: 100%; }
  .portfolio-slider-cards__background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
    .portfolio-slider-cards__background img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .portfolio-slider-cards__background img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .portfolio-slider-cards__foreground {
    display: none; }
  .portfolio-slider-cards .flickity-button {
    border: 6px solid #191D24;
    border-radius: 0;
    top: 100%;
    height: 3.5rem;
    width: 3.5rem; }
    .portfolio-slider-cards .flickity-button svg {
      display: none; }
    .portfolio-slider-cards .flickity-button.next {
      background: url("/icons/slider-right.svg") no-repeat center;
      background-color: #ffffff;
      background-size: 1rem;
      right: 1.5rem; }
    .portfolio-slider-cards .flickity-button.previous {
      background: url("/icons/slider-left.svg") no-repeat center;
      background-color: #ffffff;
      background-size: 1rem;
      left: auto;
      right: 4.75rem; }
  .portfolio-slider-cards .flickity-viewport {
    overflow: visible; }
  .portfolio-slider-cards.port-slider-cols {
    background: transparent; }
    .portfolio-slider-cards.port-slider-cols h2 {
      color: #0E1B31 !important;
      font-size: 1rem;
      font-style: normal;
      font-weight: 900;
      line-height: 1.75;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin-bottom: 3rem; }
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__card {
      background: transparent;
      padding: 0;
      margin: 0;
      height: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: end;
      justify-content: flex-end; }
      .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__card a {
        margin-top: 0; }
      .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__card a,
      .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__card h3 {
        color: white; }
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__foreground {
      display: none; }
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__slides::after,
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__slides::before {
      content: none; }
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__slide {
      width: 30%;
      margin-right: 2rem;
      height: 44rem;
      padding: 1.5rem 1.5rem 3rem; }
      .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__slide::after {
        background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.5) 100%);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1; }
  @media screen and (min-width: 48em) {
    .portfolio-slider-cards__slides {
      position: relative; }
      .portfolio-slider-cards__slides::before, .portfolio-slider-cards__slides::after {
        background: #050C1A;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: 5; }
      .portfolio-slider-cards__slides::before {
        right: 100%; }
      .portfolio-slider-cards__slides::after {
        left: 100%; }
    .portfolio-slider-cards__card {
      margin: 7.5rem auto 7.5rem 1.5rem;
      padding: 2rem; }
    .portfolio-slider-cards__foreground {
      display: block;
      height: calc(100% + 6rem);
      top: -3rem;
      left: 70%;
      bottom: -3rem;
      transform: translateX(-50%);
      width: 45%;
      z-index: 3;
      position: absolute; }
      .portfolio-slider-cards__foreground img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .portfolio-slider-cards__foreground img {
            height: 100%;
            object-fit: contain;
            object-position: center; } } }
  @media screen and (min-width: 73rem) {
    .portfolio-slider-cards__foreground {
      left: 50%; } }
  @media screen and (max-width: 47.999em) {
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__slide {
      width: 45%;
      height: 79vw; } }
  @media screen and (max-width: 29.999em) {
    .portfolio-slider-cards.port-slider-cols .portfolio-slider-cards__slide {
      width: 90%;
      height: 149vw; } }

.service-header {
  padding-top: 3rem; }
  .service-header h1 {
    font-weight: 100; }
  .service-header .portfolio-grid {
    margin-top: 0; }
    .service-header .portfolio-grid__wrapper .eyebrow {
      display: none; }
    .service-header .portfolio-grid .button--inline {
      font-size: 1.25rem; }
  .service-header .breadcrumbs {
    position: relative;
    top: -1.5rem; }
  @media screen and (max-width: 61.999em) {
    .service-header .portfolio-grid {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      .service-header .portfolio-grid__item {
        height: 18rem;
        min-width: 18rem;
        margin-right: .75rem;
        padding-bottom: 0;
        width: 18rem; }
      .service-header .portfolio-grid__wrapper {
        background: #ffffff;
        margin: 4rem 0 0 -1.5rem;
        padding: 2rem 0 0 1.5rem;
        width: calc(100% + 3rem); } }
  @media screen and (min-width: 62em) {
    .service-header {
      padding: 0 !important;
      margin-bottom: 10rem !important; }
      .service-header h1 {
        font-size: 2.42509em; } }
    @media screen and (min-width: 62em) and (min-width: 48em) {
      .service-header h1 {
        font-size: 3.03136em; } }
  @media screen and (min-width: 62em) {
      .service-header .breadcrumbs {
        top: -3rem; }
      .service-header__container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between; }
      .service-header__text {
        padding: 5rem 4rem 5rem 0;
        width: 45%; }
      .service-header .portfolio-grid {
        height: calc(100% + 5rem); }
        .service-header .portfolio-grid__item {
          padding-bottom: 44.75%;
          width: 50%; }
        .service-header .portfolio-grid__wrapper {
          margin-right: -1.5rem;
          position: relative;
          width: calc(55% + 1.5rem); } }
  @media screen and (min-width: 75em) {
    .service-header h1 {
      font-size: 3.1999em; } }
  @media screen and (min-width: 75em) and (min-width: 48em) {
    .service-header h1 {
      font-size: 3.99988em; } }
  @media screen and (min-width: 75em) {
    .service-header__text {
      width: 40%; }
    .service-header .portfolio-grid__wrapper {
      width: calc(60%); }
      .service-header .portfolio-grid__wrapper .eyebrow {
        display: block;
        position: absolute;
        top: .5rem;
        left: -1.5rem;
        transform: rotate(90deg);
        transform-origin: left; } }

.services__heading {
  margin-bottom: 3rem; }
  .services__heading .eyebrow {
    color: #F18977; }

.services__left {
  margin-bottom: 1.5rem; }

.services__list-item {
  border-left: 4px solid transparent;
  cursor: pointer;
  transition: .25s;
  padding: 1rem;
  position: relative; }
  .services__list-item:before {
    border-top: 4px solid #F18977;
    content: '';
    opacity: 0;
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    transition: .25s;
    width: 8px; }
  .services__list-item.active {
    background: black;
    border-color: #F18977;
    color: #F18977; }
    .services__list-item.active::before {
      opacity: 1; }

.services__right {
  background: radial-gradient(42.27% 340.93% at 0% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%);
  border-top: 1px solid #5B5B5B;
  padding: 1.5rem; }

.services__description {
  opacity: 0;
  position: absolute;
  transition: opacity 0s, visibility 0s;
  visibility: hidden; }
  .services__description.active {
    opacity: 1;
    position: static;
    transition: opacity .25s, visibility 0s;
    visibility: visible; }
  .services__description h4 {
    color: #F18977; }

.services.alt-ver .services__heading {
  display: none; }

.services.alt-ver .services__list {
  columns: 1; }
  .services.alt-ver .services__list-item.active {
    background: #7ACBC6;
    color: #000; }

.services.alt-ver .services__right {
  border-left: 3px solid #7ACBC6;
  background: #152641; }

@media screen and (min-width: 48em) {
  .services__heading {
    margin-bottom: 4.5rem; }
  .services__main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
  .services__left {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 60%; }
  .services__list-item {
    font-size: 1.125rem; }
  .services__right {
    border-left: 1px solid #5B5B5B;
    border-top: none;
    padding: 0 3rem 0;
    width: 40%; }
  .services__description {
    padding: 3rem 0; }
  .services.alt-ver .services__left,
  .services.alt-ver .services__right {
    width: 50%; }
  .services.services-swap .services__left {
    -ms-flex-order: 1;
    order: 1;
    padding-left: 3rem;
    padding-right: 0; }
  .services.services-swap .services__right {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, #000 100%);
    padding: 0 3rem 0 0;
    border-left: none;
    border-right: 4px solid #F18977; } }

@media screen and (min-width: 62em) {
  .services {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important; }
    .services__list {
      columns: 2;
      column-gap: 3rem; }
    .services.alt-ver .services__left {
      width: 60%; }
    .services.alt-ver .services__right {
      width: 40%; } }

/**
 * Slider ACF Layout Styles
 * DBS>Interactive
 */
.layout-slider {
  overflow: visible;
  padding: 0em 1em; }

.slider-layout__wrapper {
  margin: 0rem auto;
  max-width: 80rem;
  overflow: hidden;
  position: relative; }

.slider-cell {
  min-height: 100%;
  width: 100%; }
  .slider-cell.image-slide .slider-cell__image {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1; }
    .slider-cell.image-slide .slider-cell__image img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; }
  .slider-cell.image-slide .slider-cell__content {
    position: relative;
    z-index: 2; }
    .slider-cell.image-slide .slider-cell__content h2, .slider-cell.image-slide .slider-cell__content h3, .slider-cell.image-slide .slider-cell__content h4, .slider-cell.image-slide .slider-cell__content h5, .slider-cell.image-slide .slider-cell__content p, .slider-cell.image-slide .slider-cell__content a {
      color: #ffffff; }
  .slider-cell__content {
    padding: 1em; }

.slide-wrapper {
  overflow: hidden;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 1%; }
  @media screen and (min-width: 62em) {
    .slide-wrapper {
      margin-bottom: 0;
      width: 100%; } }

.slide__image {
  overflow: hidden;
  padding: 42%;
  min-height: 6em; }
  @media screen and (min-width: 62em) {
    .slide__image {
      padding: 30%; } }
  .slide__image img {
    position: absolute;
    left: 0;
    top: 0; }

.flickity-page-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative; }

.button-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

button.dot {
  border-radius: 100%;
  width: .75em;
  height: .75em;
  padding: 0;
  margin: 0em .5em;
  opacity: 1;
  background: transparent;
  transform: scale(1);
  border: 0.1em solid #ffffff; }
  button.dot:focus, button.dot:hover, button.dot.is-selected {
    background-color: #000;
    border-color: #ffffff;
    transform: scale(1.5);
    transition: transform .25s linear; }

.pause-start-slider {
  background: none;
  border: none;
  margin: 0.5em .5em;
  height: 2em;
  width: 2em;
  position: relative; }
  .pause-start-slider:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: url("/icons/pause-symbol.svg"); }
  .pause-start-slider.slider-paused:after, .pause-start-slider.slider-paused-from-control:after {
    content: url("/icons/play-symbol.svg"); }
  .pause-start-slider:focus, .pause-start-slider:hover {
    transition: transform .25s ease-in-out;
    transform: scale(1.2); }

.flickity-viewport:focus,
.flickity-slider:focus,
.flickity-enabled:focus {
  box-shadow: none; }

.flickity-viewport {
  transition: height .25s ease-in-out; }

.slider-button {
  background: #7ACBC6;
  border: 0;
  line-height: 0;
  padding: 2.5rem .5rem; }
  .slider-button svg {
    height: 1.25rem;
    width: auto; }
  .slider-button.prev svg {
    transform: rotate(180deg); }

.statistics__heading {
  margin: 0 auto 4rem;
  max-width: 55rem; }

.statistics__group {
  border-bottom: 1px solid #EBECEB;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center; }
  .statistics__group:last-child {
    border-bottom: none; }
  .statistics__group svg {
    width: 5rem; }
  .statistics__group .number,
  .statistics__group .prefix,
  .statistics__group .suffix {
    font-size: 2rem;
    font-weight: bold; }

.statistics__icon {
  margin: 0 auto 1rem;
  width: 5rem; }

.statistics__description {
  font-size: 1.125rem; }

@media screen and (min-width: 48em) {
  .statistics__groups {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 -1.5rem; }
  .statistics__group {
    border-bottom: none;
    border-right: 1px solid #EBECEB;
    padding-bottom: 0;
    padding: 0 1.5rem;
    width: 25%; }
    .statistics__group:last-child, .statistics__group:nth-child(4), .statistics__group:nth-child(7) {
      border-right: none; }
    .statistics__group:nth-child(5), .statistics__group:nth-child(6), .statistics__group:nth-child(7) {
      margin-top: 1.5rem; } }

@media screen and (max-width: 47.999em) {
  .statistics__group {
    border-bottom: 0;
    padding-bottom: 0;
    width: calc(50% - 0.75rem); }
    .statistics__group:nth-child(even) {
      border-left: 1px solid #F2F3F5;
      padding-left: 0.75rem; }
    .statistics__group:nth-child(odd) {
      padding-right: 0.75rem; }
  .statistics__groups {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }

.testimonials {
  position: relative; }
  .testimonials .eyebrow {
    margin-bottom: 3rem; }
  .testimonials__slide {
    width: 100%; }
    .testimonials__slide span {
      line-height: 1.5; }
  .testimonials__meta {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
  .testimonials__img {
    border-radius: 50%;
    max-height: 4.5rem;
    max-width: 4.5rem;
    min-width: 4.5rem;
    overflow: hidden;
    width: 4.5rem; }
    .testimonials__img img {
      display: block;
      width: 100%; }
  @media screen and (max-width: 47.999em) {
    .testimonials__slide {
      padding: 0 2rem; }
    .testimonials__nav {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; }
      .testimonials__nav-buttons {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between; } }
  @media screen and (min-width: 48em) {
    .testimonials__wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .testimonials__slide {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .testimonials__carousel {
      width: 80%; }
    .testimonials__icon {
      background: url("/icons/gradient-circle.svg") no-repeat center;
      background-size: cover;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      height: 8rem;
      margin-right: 3rem;
      min-height: 8rem;
      min-width: 8rem;
      width: 8rem; }
      .testimonials__icon svg {
        height: 3.5rem; }
    .testimonials__nav {
      border-left: 1px solid #7ACBC6;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      margin-left: 3rem;
      padding-left: 3rem;
      width: 20%; }
      .testimonials__nav-buttons {
        display: -ms-flexbox;
        display: flex; }
      .testimonials__nav .slider-button {
        background: 0;
        padding: 0 .75rem; }
        .testimonials__nav .slider-button:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.3);
          padding: .25rem 1.5rem .25rem 0; }
        .testimonials__nav .slider-button:last-child {
          padding: .25rem 0 .25rem 1.5rem; }
        .testimonials__nav .slider-button svg polygon {
          fill: #7ACBC6; } }
  @media screen and (min-width: 62em) {
    .testimonials__icon {
      height: 9rem;
      min-height: 9rem;
      min-width: 9rem;
      width: 9rem; } }

/**
 * Three Columns Flex Layout
 * DBS>Interactive
 */
.three-columns .column {
  width: 100%; }
  @media screen and (min-width: 62em) {
    .three-columns .column {
      width: 33.33333%; } }

@media screen and (max-width: 47.999em) {
  .three-columns .column .cell {
    padding-left: 0;
    padding-right: 0; } }

@media screen and (min-width: 62em) {
  .three-columns {
    /**
		 * This little bit of hackery makes the edge of background images and
		 * background colors line up with the container content when a contain
		 * class is on the half and half layout, but not when the
		 * 'contain-full-width' reset class is used.
		 */ }
    .three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:first-of-type, .three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:last-of-type {
      width: calc(33.3333% - 1.5rem); }
    .three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:first-of-type {
      margin-left: 1.5rem; }
    .three-columns[class*=contain]:not([class~=contain-full-width]) [class*=bg-].column:last-of-type {
      margin-right: 1.5rem; }
    .three-columns .columns {
      margin: 0 -1.5rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  opacity: 0;
  position: relative;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.has-img, .two-columns__right.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }

.two-columns__left {
  transform: translate3d(-2rem, 0, 0); }

.two-columns__right {
  transform: translate3d(2rem, 0, 0); }

.two-columns .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-columns .animation-trigger.in-view ~ .two-columns__left,
.two-columns .animation-trigger.in-view ~ .two-columns__right {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; }
  .two-columns.full-bleed .has-img {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .two-columns + .full-bleed {
    margin-top: 1.5rem !important; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 3rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left.contain-img, .two-columns__left.cover, .two-columns__right.contain-img, .two-columns__right.cover {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; }
  .two-columns.full-bleed {
    min-height: 30rem;
    position: relative; }
    .two-columns.full-bleed .contain {
      -ms-flex-align: center;
      align-items: center;
      min-height: 30rem; }
    .two-columns.full-bleed .two-columns__left:not(.has-img),
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      padding: 4.5rem 0; }
    .two-columns.full-bleed .two-columns__left:not(.has-img) {
      margin-right: auto; }
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      margin-left: auto; }
    .two-columns.full-bleed .has-img {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 50%; }
      .two-columns.full-bleed .has-img.two-columns__left {
        left: 0; }
      .two-columns.full-bleed .has-img.two-columns__right {
        right: 0; }
      .two-columns.full-bleed .has-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .two-columns.full-bleed .has-img img {
            height: 100%;
            object-fit: cover;
            object-position: center top; } } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__left {
    width: calc(38% - 3rem); } }

.two-columns.two-thirds-right img {
  height: auto;
  width: 100%; }

.two-columns.two-thirds-right .two-columns__right.bg-img {
  padding-left: 0; }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__right {
    width: calc(62% - 3rem); } }

.two-columns.two-thirds-left img {
  height: auto;
  width: 100%; }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-left .two-columns__left {
    width: calc(62% - 3rem); }
    .two-columns.two-thirds-left .two-columns__left.bg-img {
      padding-right: 0; }
  .two-columns.two-thirds-left .two-columns__right {
    width: calc(38% - 3rem); } }

.why-dbs__download {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3rem;
  padding: 1.5rem; }
  .why-dbs__download-icon {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
    min-width: 4rem; }
    .why-dbs__download-icon img {
      width: 100%; }
  .why-dbs__download .button--inline {
    max-width: 15rem; }

.why-dbs__blog {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5rem; }
  .why-dbs__blog-img {
    background: #050C1A;
    height: 7rem;
    margin-right: 1rem;
    min-width: 7rem;
    overflow: hidden;
    position: relative;
    width: 7rem; }
    .why-dbs__blog-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .why-dbs__blog-img img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .why-dbs__blog .button--inline {
    padding: 0 0 1.5rem; }
    .why-dbs__blog .button--inline:after {
      bottom: 0;
      left: 0;
      right: auto;
      top: auto;
      transform: translate(0, 0); }
    .why-dbs__blog .button--inline:hover:after, .why-dbs__blog .button--inline:focus:after {
      transform: translate(0.5rem, 0); }

@media screen and (max-width: 47.999em) {
  .why-dbs__left {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .why-dbs__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .why-dbs__left {
    width: 60%; }
  .why-dbs__right {
    font-size: .85rem;
    padding-left: 3rem;
    width: 40%; }
  .why-dbs__blog-img {
    height: 10rem;
    min-width: 10rem;
    width: 10rem; } }

.phases .eyebrow {
  color: #7ACBC6; }

.phases hr {
  background: linear-gradient(to right, #7ACBC6, #F18977);
  border: none;
  height: 1px;
  margin: 1.5rem auto 1.5rem 0;
  width: 6rem; }

.phases__top {
  margin-bottom: 3rem;
  max-width: 59rem !important; }

.phases__row {
  margin-bottom: 3rem;
  position: relative; }
  .phases__row:last-child {
    margin-bottom: 0; }
  .phases__row ul {
    columns: 2;
    column-gap: 2rem;
    margin: 0; }
    .phases__row ul li {
      break-inside: avoid-column; }

.phases blockquote {
  border-left: 1px solid #7acbc6;
  margin: 2rem 0;
  overflow: hidden;
  padding: .75rem 1.5rem;
  position: relative; }
  .phases blockquote p {
    margin-left: 0; }

@media screen and (min-width: 48em) {
  .phases__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 72rem !important; }
  .phases__left {
    padding-right: 3rem;
    width: 40%; }
  .phases__right {
    width: 60%; }
  .phases__row {
    padding-left: 6rem; }
  .phases__icon {
    height: 4.5rem;
    width: 4.5rem;
    position: absolute;
    top: 0;
    left: 0; }
    .phases__icon img {
      width: 100%; } }

@media screen and (min-width: 48em) {
  .phases.two-up .phases__container {
    max-width: 80rem !important; }
  .phases.two-up .phases__right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%; }
    .phases.two-up .phases__right .phases__row {
      width: calc(50% - 1.5rem); }
      .phases.two-up .phases__right .phases__row:nth-last-child(-n+2) {
        margin-bottom: 0; } }

.layout.who-contact {
  margin-bottom: 15rem; }
  @media screen and (min-width: 48em) {
    .layout.who-contact {
      margin-bottom: 5rem; } }

.who-contact__top {
  margin-bottom: 2rem; }
  .who-contact__top-icon {
    margin-bottom: 1.5rem; }

.who-contact__form {
  background: #7ACBC6;
  margin: 0 auto -14rem;
  max-width: 23rem;
  padding: 1.5rem; }
  .who-contact__form h3 {
    color: #050C1A;
    font-size: 7vw; }
  .who-contact__form .button.arrow {
    display: block;
    margin-bottom: -3.85rem;
    margin-top: 2rem; }
  .who-contact__form button[type=submit] {
    background-color: #7ACBC6;
    border: 1px solid #7ACBC6;
    border-radius: 0;
    color: #050C1A;
    display: inline-block;
    font-weight: 900;
    letter-spacing: 2px;
    padding: 1.5em 1.5em;
    text-align: center;
    text-decoration: none;
    transition: background-color .25s ease, border-color .25s ease, color .25s ease;
    text-transform: uppercase;
    -webkit-appearance: nowrap;
    white-space: nowrap;
    padding-bottom: 1rem;
    padding-top: 1rem; }
    .who-contact__form button[type=submit]:hover, .who-contact__form button[type=submit]:active, .who-contact__form button[type=submit]:focus {
      background-color: #050C1A;
      border-color: #7ACBC6;
      color: #7ACBC6; }
    @media screen and (min-width: 48em) {
      .who-contact__form button[type=submit] {
        padding: 1.5em 2.5em; } }
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text],
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email],
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel],
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea {
    border-radius: 6px !important;
    border: 1px solid rgba(38, 55, 83, 0.42) !important;
    font-weight: 600 !important; }
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text]:-ms-input-placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email]:-ms-input-placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel]:-ms-input-placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea:-ms-input-placeholder {
      font-weight: 600; }
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text]::placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email]::placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel]::placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea::placeholder {
      font-weight: 600; }
  .who-contact__form .freeform-row .floating label,
  .who-contact__form .freeform-row label {
    background: none !important;
    font-family: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif !important;
    font-weight: 600 !important;
    opacity: 1 !important; }
  .who-contact__form .freeform-fieldtype-rich-text {
    font-family: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif; }

@media screen and (min-width: 30em) {
  .who-contact h3 {
    font-size: 1.83788em; } }
  @media screen and (min-width: 30em) and (min-width: 48em) {
    .who-contact h3 {
      font-size: 2.29736em; } }

@media screen and (min-width: 48em) {
  .who-contact__top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
    .who-contact__top-icon {
      padding-right: 3rem;
      width: 50%; }
    .who-contact__top-text {
      width: 50%; }
  .who-contact__form {
    background: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(5, 12, 26, 0.38);
    margin: 0 auto -7.5rem;
    max-width: none; }
    .who-contact__form h3 {
      color: #1869B9; } }

@media screen and (min-width: 62em) {
  .who-contact__form {
    display: -ms-flexbox;
    display: flex;
    padding: 2.5rem; }
    .who-contact__form-text {
      padding-right: 3rem;
      width: 50%; }
      .who-contact__form-text h3 {
        max-width: 30rem; }
    .who-contact__form .mobile-hidden {
      width: 50%; }
    .who-contact__form .textarea {
      max-height: 8rem; } }

@media screen and (max-width: 47.999em) {
  .page- .who-contact {
    margin-bottom: 0; } }

@media screen and (min-width: 48em) {
  .page- .who-contact .who-contact__top {
    -ms-flex-pack: center;
    justify-content: center; }
  .page- .who-contact .who-contact__top-icon {
    margin-bottom: 0;
    max-width: 24rem;
    padding-right: 4.5rem; }
  .page- .who-contact:after {
    background-position: -20vw 50%;
    background-size: auto 300%; } }

/**
 * Collapsible Layout
 * DBS>Interactive
 */
.layout-collapsible {
  margin: 4rem auto; }
  @media screen and (min-width: 48em) {
    .layout-collapsible {
      margin: 5rem auto; } }
  .layout-collapsible .collapsible-row {
    overflow: hidden;
    border-top: 1px solid #ffffff; }
    .layout-collapsible .collapsible-row:first-of-type {
      border: none; }
    .layout-collapsible .collapsible-row__header {
      font-size: 1.0556em;
      background: #F2F3F5;
      border: none;
      color: #050C1A;
      cursor: pointer;
      display: block;
      margin-bottom: 0;
      padding: 1rem 4rem 1rem 2rem;
      position: relative;
      text-align: left;
      transition: .3s;
      width: 100%; }
      @media (min-width: 48em) {
        .layout-collapsible .collapsible-row__header {
          font-size: 1.3195em; } }
      .layout-collapsible .collapsible-row__header:hover, .layout-collapsible .collapsible-row__header:focus {
        background: #F2F3F5; }
      .layout-collapsible .collapsible-row__header:before, .layout-collapsible .collapsible-row__header:after {
        background-color: #050C1A;
        border-radius: 10%;
        content: '';
        display: block;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        right: 2rem;
        transition: transform .35s;
        transform: translate(-100%, -50%);
        width: .18rem; }
      .layout-collapsible .collapsible-row__header:before {
        transform: translate(-100%, -50%) rotate(90deg); }
      .layout-collapsible .expanded .collapsible-row__header {
        background: #F2F3F5; }
        .layout-collapsible .expanded .collapsible-row__header:after {
          transform: translate(-100%, -50%) rotate(90deg); }
    .layout-collapsible .collapsible-row__content {
      padding: 2rem; }
      @media screen and (min-width: 48em) {
        .layout-collapsible .collapsible-row__content {
          padding: 2rem 2rem 3rem; } }
    .layout-collapsible .collapsible-row__content--wrapper {
      overflow: hidden;
      transition: max-height .4s ease-in-out, visibility 0s linear .5s; }
      .layout-collapsible .collapsible-row__content--wrapper.loaded {
        max-height: 0; }
      .layout-collapsible .expanded .collapsible-row__content--wrapper {
        max-height: 15em;
        transition: max-height .4s ease-in-out, visibility 0s linear; }

/**
 *	Styles for the sidebar layout
 *
 *	This stylesheet properties only for layout-content_with_sidebar
 *		blog page has it's own stylesheet for sidebar
 *	[-If you want to make it global you can do it so-]
 */
.layout-content-with-sidebar.active-sidebar .contain {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media screen and (min-width: 75em) {
    .layout-content-with-sidebar.active-sidebar .contain {
      -ms-flex-direction: row;
      flex-direction: row; } }

@media screen and (min-width: 75em) {
  .layout-content-with-sidebar.active-sidebar .content {
    width: 70%; } }

.layout-content-with-sidebar.active-sidebar .content > .layout:first-child {
  margin-top: 0rem; }

.layout-content-with-sidebar.active-sidebar .content .layout:last-child {
  margin-bottom: 0; }

.layout-content-with-sidebar.active-sidebar .content .layout .contain, .layout-content-with-sidebar.active-sidebar .content .layout.contain {
  padding-left: 0;
  padding-right: 0; }

.layout-content-with-sidebar.active-sidebar .content .half-and-half__left.bg-img {
  margin-left: 0;
  width: 50%; }

.layout-content-with-sidebar.active-sidebar .content .half-and-half__right.bg-img {
  margin-right: 0;
  width: 50%; }

@media screen and (min-width: 75em) {
  .layout-content-with-sidebar.active-sidebar .sidebar {
    padding-right: 3rem;
    width: 30%; } }

/**
 * Image Grid (with sidebar)
 * DBS>Interactive
 *
 * ACF Special Classes styled by this stylesheet:
 *		.light-box (Light Box)
 *		.image-zoom (Image Zoom)
 *
 * Note: The .light-box class included later in this stylesheet
 * 		cooresponds with functionality included in lightbox/lightbox.js
 */
.image-gallery__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media screen and (min-width: 62em) {
    .image-gallery__wrapper {
      -ms-flex-direction: row;
      flex-direction: row; } }

.image-gallery__content {
  padding: 1.5rem; }
  @media screen and (min-width: 62em) {
    .image-gallery__content {
      width: 50%;
      display: -ms-inline-flexbox;
      display: inline-flex; } }
  .image-gallery__content .inner {
    max-width: 35em;
    margin: 0 auto;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  @media screen and (min-width: 62em) {
    .image-gallery__content + .image-gallery__images {
      width: 50%; } }

.image-gallery__images {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%; }

.image-gallery__image {
  border: 0.1em solid #ffffff;
  box-shadow: 0 0;
  height: 0;
  padding: 0;
  padding-bottom: 66%;
  position: relative;
  overflow: hidden;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .image-gallery__image {
      width: 50%;
      padding-bottom: 35%; } }
  @media screen and (min-width: 62em) {
    .image-gallery__image {
      width: 33.33333%;
      padding-bottom: 25%; } }
  @media screen and (min-width: 62em) {
    .image-gallery__image {
      width: 25%;
      padding-bottom: 20%; } }
  .image-gallery__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .image-gallery__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.individual-lightbox {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999; }
  .individual-lightbox.opened-box {
    display: inline-block; }
  .individual-lightbox .backDrop {
    background: #000;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: .8; }
  .individual-lightbox .image-container {
    background: #ffffff;
    border: 0.75rem solid #ffffff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 60rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 3rem); }
  .individual-lightbox .close-lightbox {
    background: url("../icons/close-symbol.svg") no-repeat center;
    background-size: 2rem;
    background-color: #ffffff;
    border: none;
    font-size: 2.25em;
    height: 2em;
    width: 2em;
    position: absolute;
    right: -.75rem;
    top: -.75rem; }
  .individual-lightbox .largeImage {
    max-height: 80vh;
    width: 100%; }
  .individual-lightbox .lightbox-caption {
    background: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    padding: 1em;
    width: 100%; }
  .individual-lightbox .contain-size .lightbox-caption {
    display: none; }

/**
 * Mixin to create a multi-directional animation with opacity fade.
 *
 * @example: 
 * @include animate3D( fadeRight, -3rem, 0, 0, 0);
 * .slideRight {animation: fadeRight 1s ease 0s 1 both;}
 */
/**
 * Several basic examples are below, but they are being kept commented out
 * to keep unnecessary code from making its way into production. For new animations
 * that involve a "transform: translate", use "translate3d" since it will perform better 
 * because of hardware acceleration.
 */
/**
 * Slide left to right and fade in from invisible 
 */
/**
 * Slide right to left and fade in from invisible 
 */
/**
 * Slide up and fade in from invisible 
 */
/**
 * Slide down and fade in from invisible 
 */
/**
 * Fade in only
 */
/**
 * Bounce
 */
/**
 * Not enough? This site has a lot of great animations with the ability to copy the
 * source code. https://animista.net/play/
 */
@keyframes opacity {
  0% {
    opacity: 0.01; }
  100% {
    opacity: 1; } }

@keyframes slideRight {
  0% {
    transform: translate3d(-3rem, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes slideUp {
  0% {
    transform: translate3d(0, 3rem, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.flex {
  display: -ms-flexbox;
  display: flex; }

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-col-r {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row; }

.flex-row-r {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.aic {
  -ms-flex-align: center;
  align-items: center; }

.aie {
  -ms-flex-align: end;
  align-items: flex-end; }

.asc {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center; }

.ase {
  -ms-flex-item-align: end;
  align-self: flex-end; }

.ass {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.jcc {
  -ms-flex-pack: center;
  justify-content: center; }

.jce {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.jcsa {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.jcsb {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.jcse {
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly; }

.fb-25 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%; }

.fb-50 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%; }

.fb-75 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%; }

.gap {
  gap: 1.5rem; }

.gap-2 {
  gap: 3rem; }

@media screen and (max-width: 47.999em) {
  .flex-sm {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-sm {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-sm {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-sm {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-sm {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .wrap-sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .aic-sm {
    -ms-flex-align: center;
    align-items: center; }
  .aie-sm {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-sm {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-sm {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-sm {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-sm {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-sm {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-sm {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-sm {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .fb-25-sm {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-sm {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-sm {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 48em) {
  .flex-tab {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-tab {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-tab {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-tab {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-tab {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-tab {
    -ms-flex-align: center;
    align-items: center; }
  .aie-tab {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-tab {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-tab {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-tab {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-tab {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-tab {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-tab {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-tab {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-tab {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-tab {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-tab {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-tab {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-tab {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-tab {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 62em) {
  .flex-md {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-md {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-md {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-md {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-md {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-md {
    -ms-flex-align: center;
    align-items: center; }
  .aie-md {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-md {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-md {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-md {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-md {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-md {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-md {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-md {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-md {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-md {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-md {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

@media screen and (min-width: 75em) {
  .flex-lg {
    display: -ms-flexbox;
    display: flex; }
  .flex-col-lg {
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-col-r-lg {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
  .flex-row-lg {
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-row-r-lg {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .aic-lg {
    -ms-flex-align: center;
    align-items: center; }
  .aie-lg {
    -ms-flex-align: end;
    align-items: flex-end; }
  .asc-lg {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center; }
  .ase-lg {
    -ms-flex-item-align: end;
    align-self: flex-end; }
  .ass-lg {
    -ms-flex-item-align: start;
    align-self: flex-start; }
  .jcc-lg {
    -ms-flex-pack: center;
    justify-content: center; }
  .jce-lg {
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .jcsa-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .jcsb-lg {
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .jcse-lg {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .wrap-lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .nowrap-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .fb-25-lg {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%; }
  .fb-50-lg {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%; }
  .fb-75-lg {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%; } }

/**
 * Form Styles
 * DBS>Interactive
 */
fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text],
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
select {
  display: block;
  border: 1px solid rgba(38, 55, 83, 0.42);
  border-radius: 6px;
  max-width: 100%;
  padding: .75em;
  width: 100%; }

fieldset {
  margin-bottom: 3rem; }

/**
 * Remove IE's clear so we don't conflict with the add-clear button functionality 2017-09-12
 */
input[type=text]::-ms-clear {
  display: none; }

.g-id,
.user-path {
  display: none !important; }

/**
 * Re-usable items related to portfolio layouts/pages
 */
.portfolio-grid__item {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .portfolio-grid__item-background, .portfolio-grid__item-background:after {
    height: 101%;
    width: 101%;
    position: absolute;
    top: -1px;
    left: 0; }
  .portfolio-grid__item-background:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
    content: ''; }
  .portfolio-grid__item-background img {
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .portfolio-grid__item-text {
    padding: 1.5rem;
    padding-bottom: 2rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    transition: background .35s ease-in-out;
    width: 100%;
    will-change: background; }
    .portfolio-grid__item-text .button--inline {
      display: block;
      margin-bottom: 1rem; }
  .portfolio-grid__item-excerpt {
    line-height: 1.3;
    max-height: 0;
    overflow: hidden;
    transition: max-height .35s ease-in-out;
    will-change: max-height; }
  .portfolio-grid__item:hover .portfolio-grid__item-text, .portfolio-grid__item:focus .portfolio-grid__item-text {
    background-color: rgba(5, 12, 26, 0.77); }
  .portfolio-grid__item:hover .portfolio-grid__item-excerpt, .portfolio-grid__item:focus .portfolio-grid__item-excerpt {
    max-height: 10rem; }

@media screen and (min-width: 48em) {
  .portfolio-grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .portfolio-grid__item {
      padding-bottom: 50%;
      width: 50%; } }

@media screen and (min-width: 62em) {
  .portfolio-grid__item {
    padding-bottom: 33.33%;
    width: 33.33%; } }

.portfolio-header {
  margin-top: 0; }
  .portfolio-header ul {
    columns: 2;
    list-style-type: none;
    padding-left: 0; }
    .portfolio-header ul li a {
      font-weight: 400;
      text-decoration: none; }
    .portfolio-header ul li:not(:last-child) {
      margin-bottom: 1.5rem; }
  .portfolio-header__image {
    margin-bottom: 4rem; }
  @media screen and (min-width: 48em) {
    .portfolio-header__container {
      display: -ms-flexbox;
      display: flex; }
    .portfolio-header__content {
      padding-right: 6rem;
      width: 65%; }
    .portfolio-header__content > *:first-child, .portfolio-header__services > *:first-child {
      margin-bottom: 2rem; }
    .portfolio-header__services {
      width: 35%; }
    .portfolio-header__image {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 47.999em) {
    .portfolio-header__content {
      margin-bottom: 3rem; }
      .portfolio-header__content .h1 {
        font-size: 2.5rem; } }

/**
 * Table Styles with Responsive Design
 * Based on a CodePen Example by James TW:
 * https://codepen.io/james-tw/pen/GoaXrz
 */
/* Variables */
/**
 * Default styles
 */
table {
  margin-bottom: 1em;
  max-width: 100%;
  width: 100%;
  /**
	 * Responsive desktop styles
	 */
  /**
	 * Mobile only styles
	 */ }
  table tbody {
    display: block; }
  table tr {
    border: 1px solid #e0e0e0;
    display: block;
    margin-bottom: 1rem;
    transition: background-color .25s ease; }
  table th,
  table td {
    border-top: 0;
    padding: 1rem;
    transition: background-color .25s ease;
    text-align: left;
    vertical-align: top; }
  table th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: #757575;
    font-weight: 400;
    vertical-align: bottom; }
  table td {
    display: block;
    text-align: right; }
  table tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    table tfoot td {
      color: #757575;
      font-weight: 400; }
  @media screen and (min-width: 48em) {
    table tbody {
      display: table-row-group; }
    table tr {
      border: none;
      display: table-row; }
    table td {
      display: table-cell;
      text-align: left; } }
  @media screen and (max-width: 47.999em) {
    table thead,
    table tfoot {
      display: none; }
    table td[data-title]:before {
      color: #757575;
      content: attr(data-title);
      float: left;
      font-size: inherit;
      font-weight: 400; }
    table:not(._) td:first-child {
      background-color: #404144;
      border: none;
      color: #ffffff;
      font-weight: 500;
      text-align: left; }
      table:not(._) td:first-child a {
        color: #ffffff; }
      table:not(._) td:first-child::before {
        display: none; } }

/* Table classes -- Add manually to the table element within the CMS */
/**
 * Bordered table
 *
 * Add horizontal borders between columns.
 */
.table--bordered {
  border: 0; }
  .table--bordered th,
  .table--bordered td {
    border-bottom: 1px solid #e0e0e0; }
    @media screen and (min-width: 48em) {
      .table--bordered th,
      .table--bordered td {
        border: 1px solid #e0e0e0; } }
  .table--bordered thead th,
  .table--bordered thead td {
    border-bottom-width: 2px; }

/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--striped td:nth-child(odd) {
  background-color: #f5f5f5; }

/**
 * Hover effect styling
 */
@media screen and (min-width: 48em) {
  .table--hover tr:hover {
    cursor: pointer; }
    .table--hover tr:hover td,
    .table--hover tr:hover td:first-child {
      background-color: rgba(0, 0, 0, 0.05); } }
