.client-screens {
	position: relative;
    
    .contain {
        width: 100%;
    }

	&__buttons {
		gap: $spacing;
	}

	&__screens {
		pointer-events: none;

		&--wrapper {
			height: 100%;
			overflow: hidden;
			position: absolute;
				left: 35%;
			width: 70%;
			z-index: 5;

			&::after {
				background: url('/images/laptop-frame.png') no-repeat left;
					background-size: auto 100%;
				content: '';
				height: 100%;
				position: absolute;
					top: 0;
					left: 0;
				width: 100%;
				z-index: 6;
			}
		}
	}

	&__screen {
		height: 100%;
		opacity: 0;
		overflow: hidden;
		position: absolute;
			top: 3%;
			left: 6%;
		width: 100%;

		img { @include object-fit(cover, top left); }

		&.active {
			opacity: 1;
		}
	}

	&__content {
		margin-bottom: 3rem;
	}

	&__button {
		appearance: none;
		background: transparent;
		border: none;
		border-bottom: 4px solid transparent;
		height: 0;
		padding-bottom: 20%;
		position: relative;
		transition: .25s border-color, .25s background;
		width: calc(50% - #{$spacing / 2});

		&.active {
			background: rgba($black, .2);
			border-color: $coral;
		}

		img {
			@include object-fit(contain, center);
			height: 40%;
			width: 70%;
		}
	}

	&__cta {
		margin-top: 3rem;
		position: relative;

		.button {
			background-color: $blue !important;
			border-color: $blue !important;
			color: $white !important;
			z-index: 2;

			&::before {
				background-image: url('#{$libraryPath}/icons/arrow-teal.svg') !important;
				opacity: 1 !important;
			}

			&::after {
				background: $blue !important;
				content: '';
				height: calc(100% + 2px);
				opacity: 1 !important;
				position: absolute;
					top: -1px;
					left: 100%;
				transform: none !important;
				width: 100vw;
			}
		}
	}

	@include media($screen-sm-max, 'max') {
		&__screens {
			margin-top: 3rem;

			&--wrapper {
				height: 0;
				left: auto;
				right: -20vw;
				padding-bottom: 65%;
				position: relative;
				width: calc(100% + 20vw);
			}
		}

		&__screen {
			height: 88%;
			top: 3%;
			left: 6%;
			width: 72%;
		}
	}

	@include media($screen-sm) {
        display: flex;
            justify-content: space-between;
        
		&__left {
			align-self: start;
            position: relative;
			min-width: 35%;
            z-index: 1;
		}

		&__buttons {
			gap: 3rem 1.5rem;
		}

		&__screen {
            aspect-ratio: 1000 / 695;
            height: 89%;
            object-fit: contain;
            position: absolute;
                top: 50%;
                left: 9%;
            transform: translate(0, -50%);
            width: auto;
		}

		&__screens {
            aspect-ratio: 1000 / 695;
            margin-left: auto;
            max-width: calc(100% - 35%);
            min-width: 48rem;
            aspect-ratio: 1000 / 695;
			position: relative;
                right: 0;
                top: 50%;
            transform: translate(0, -50%);
			width: 65%;
            z-index: 2;
            height: auto;

			&--wrapper {
				left: 0;
                width: 100%;
			}
		}

		&__content {
			margin-bottom: 4.5rem;
		}
	}
    
    @include media($screen-md) {
        &__screens {
            &--wrapper {
                left: unset;
                right: #{$spacing * -1};
            }
        }
    }
    
    @include media(80rem) {
        justify-content: flex-start; // Ensure the left content doesn't stretch
        
        &__left {
            width: 25.5rem;
        }
        
        &__screens {
            height: 608px; // Lock the height
            margin-left: unset; // Remove auto margin
            max-width: 800px; // Lock the width
            width: 800px; // Prevent any stretching
            right: 0; // Stick to the right edge
            position: absolute; // Break it out of the flow
            transform: translate(0, -50%); // Keep vertical centering intact
        }
    
    }
}