.brands {
	content-visibility: auto;
	padding: 3rem 0;

	.eyebrow {margin-bottom: 2.5rem;}

	&__icons {
		display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
		margin: 0 -1rem;
		width: calc(100% + 2rem)
	}

	&__icon {
		opacity: .8;
		padding: 1rem;
		text-align: center;
		width: 33.33%;

		img {
			display: block;
			height: auto;
			max-height: 2rem;
			width: 100%;
		}
	}

	@include media( $screen-sm-max, 'max' ) {
		&__icon:nth-child(2) { display: none; }
	}

	@include media($screen-sm) {
		&__icons {
			margin: 0 -#{$spacing};
			width: calc(100% + #{$spacing} * 2)
		}

		&__icon {
			flex: 1;
			padding: 0 $spacing;
			width: auto;

			&.springstone {flex-basis: 12%;}

			&.papa-johns img {max-height: 2.25rem;}

			img {max-height: 1.75rem;}
		}
	}

	// Variant used on the "about" page
	&.living-brand {
		margin-top: 3rem;
		padding: 0;

		.brands__icon {
			width: 50%;
			opacity: 1;

			img {max-height: 2.75rem;}
		}

		@include media( $screen-sm-max, 'max' ) {
			.brands__icon:nth-child(2) { display: block; }
			.brands__icon:nth-child(5) { display: none; }
		}
	}

	&.bg-dark-blue {
		background: #1A2E4C;
	}
}
